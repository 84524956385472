var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"footer-top__form footer-form",class:{'filled': _vm.isFilled && !invalid},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.isSubmitted)?_c('div',{staticClass:"footer-form__status-message"},[_vm._v("\n            Заявка успешно отправлена\n        ")]):_vm._e(),_vm._v(" "),_c('validation-provider',{ref:"name",attrs:{"name":_vm.localized.fields.name,"rules":"required","tag":"div","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group footer-form__field",class:{'error': errors.length}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-group__input",attrs:{"type":"text","autocomplete":"off","name":"name","id":"name","placeholder":" "},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('label',{staticClass:"form-group__label",attrs:{"for":"name"}},[_vm._v("\n                    "+_vm._s(_vm.localized.fields.name)+" *\n                ")])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{ref:"phone",attrs:{"name":_vm.localized.fields.phone,"rules":"required","tag":"div","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group footer-form__field",class:{'error': errors.length}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"},{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],staticClass:"form-group__input",attrs:{"type":"tel","autocomplete":"off","name":"phone","id":"phone","placeholder":" "},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('label',{staticClass:"form-group__label",attrs:{"for":"phone"}},[_vm._v("\n                    "+_vm._s(_vm.localized.fields.phone)+" *\n                ")])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{ref:"email",attrs:{"name":_vm.localized.fields.email,"rules":"email","tag":"div","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group footer-form__field footer-form__field_wide",class:{'error': errors.length}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-group__input",attrs:{"type":"text","autocomplete":"off","name":"email","id":"email","placeholder":" "},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]),_vm._v(" "),_c('label',{staticClass:"form-group__label",attrs:{"for":"email"}},[_vm._v("\n                    "+_vm._s(_vm.localized.fields.email)+"\n                ")])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group footer-form__field footer-form__field_wide"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-group__input",attrs:{"name":"message","id":"message","placeholder":" ","rows":"5"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_vm._v(" "),_c('label',{staticClass:"form-group__label",attrs:{"for":"message"}},[_vm._v("\n                "+_vm._s(_vm.localized.fields.message)+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"form-submit footer-form__submit"},[_c('div',{staticClass:"form-submit__text",domProps:{"innerHTML":_vm._s(_vm.localized.agreement)}}),_vm._v(" "),_c('vue-recaptcha',{ref:"recaptcha",attrs:{"size":"invisible","sitekey":_vm.recapture.key},on:{"verify":_vm.onRecaptureVerify}}),_vm._v(" "),_c('button',{staticClass:"form-submit__btn",attrs:{"type":"submit","disabled":invalid || !_vm.isFilled}},[_vm._v("\n                "+_vm._s(_vm.localized.submit_button)+"\n            ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }