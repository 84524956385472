window.Vue = require('vue').default;

/**
 * Vee-validate integration
 */
import messagesRu from 'vee-validate/dist/locale/ru';
import {Validator, ValidationObserver, ValidationProvider} from "vee-validate";

Validator.localize('ru', messagesRu);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('callback-form', require('./vue-components/callback-form.vue').default);
Vue.component('posts-collection', require('./vue-components/posts/collection').default);
Vue.component('product-cases-collection', require('./vue-components/product-cases/collection').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
    el: '#app'
});
