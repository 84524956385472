require('./vue');

import AOS from 'aos';
import Swiper, {Navigation, Pagination} from 'swiper';

// burger menu
function getScrollbarWidth() {

    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

function initBurgerMenu() {
    const burgerBtn = document.querySelector('.burger-js');
    const headerMenu = document.querySelector('.header-menu-js');
    const menuItems = headerMenu.querySelectorAll('a');

    if (!burgerBtn || !headerMenu) return;

    let scrollbarWidth = getScrollbarWidth();
    headerMenu.style.paddingRight = `${scrollbarWidth}px`;

    burgerBtn.addEventListener('click', function () {

        document.body.classList.toggle('no-scroll');

        scrollbarWidth = getScrollbarWidth();
        headerMenu.style.paddingRight = `${scrollbarWidth}px`;

        if (document.body.classList.contains('no-scroll')) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
        } else {
            document.body.style.paddingRight = '';
        }

        burgerBtn.classList.toggle('active');
        headerMenu.classList.toggle('active');
    });
    menuItems.forEach(item => {
        item.addEventListener('click', function () {
            document.body.classList.remove('no-scroll');
            document.body.style.paddingRight = '';
            burgerBtn.classList.remove('active');
            headerMenu.classList.remove('active');
        })
    });
}

initBurgerMenu();

// menu dropdown

function initMenuDropdowns() {
    const dropdowns = [...document.querySelectorAll('.menu-dropdown')];

    if (!dropdowns.length) return;

    dropdowns.forEach(item => {
        const itemHead = item.querySelector('.menu-dropdown__head');
        const itemList = item.querySelector('.menu-dropdown__list');

        if (!itemHead || !itemList) return;

        itemHead.addEventListener('click', function () {
            itemHead.classList.toggle('active');
        })
    });
}

initMenuDropdowns();

// swipers
function initSwipers() {
    const introSwiperContainer = document.querySelector('.intro__swiper');
    const postsSwiperContainer = document.querySelector('.posts-swiper__swiper');
    const casesSwiperContainer = document.querySelector('.cases-swiper__swiper');
    const designSwiperContainer = document.querySelector('.swiper-design__swiper');
    const leftPicSwiperContainer = document.querySelector('.left-pic__swiper');
    const giftCardsSwiperContainer = document.querySelector('.gift-cards-swiper');

    if (introSwiperContainer) {
        new Swiper('.intro__swiper', {
            modules: [Pagination],
            slidesPerView: 3,
            spaceBetween: 70,
            pagination: {
                el: '.intro__swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                770: {
                    slidesPerView: 2,
                },
                1025: {
                    slidesPerView: 3,
                }
            },
        });
    }

    if (postsSwiperContainer) {
        new Swiper('.posts-swiper__swiper', {
            modules: [Navigation],
            spaceBetween: 50,
            autoHeight: true,
            loop: true,
            navigation: {
                nextEl: '.posts-swiper__next',
                prevEl: '.posts-swiper__prev',
            },
        });
    }

    if (casesSwiperContainer) {
        let intFrameWidth = window.innerWidth;
        if (intFrameWidth > 1024) {
            new Swiper('.cases-swiper__swiper', {
                modules: [Navigation],
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                autoHeight: true,
                loop: true,
                navigation: {
                    nextEl: '.cases-swiper__next',
                    prevEl: '.cases-swiper__prev',
                },
            });
        } else {
            new Swiper('.cases-swiper__swiper', {
                modules: [Navigation],
                slidesPerView: 1,
                spaceBetween: 20,
                autoHeight: false,
                loop: true,
                navigation: {
                    nextEl: '.cases-swiper__next',
                    prevEl: '.cases-swiper__prev',
                },
            });
        }

        window.addEventListener('resize', function () {
            let frameWidth = window.innerWidth;
            let swiper = casesSwiperContainer.swiper;

            if (frameWidth > 1024) {
                swiper.destroy();
                new Swiper('.cases-swiper__swiper', {
                    modules: [Navigation],
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    autoHeight: true,
                    loop: true,
                    navigation: {
                        nextEl: '.cases-swiper__next',
                        prevEl: '.cases-swiper__prev',
                    },
                });
            } else {
                swiper.destroy();
                new Swiper('.cases-swiper__swiper', {
                    modules: [Navigation],
                    slidesPerView: 1,
                    spaceBetween: 20,
                    autoHeight: false,
                    loop: true,
                    navigation: {
                        nextEl: '.cases-swiper__next',
                        prevEl: '.cases-swiper__prev',
                    },
                });
            }
        });
    }

    if (designSwiperContainer) {
        new Swiper('.swiper-design__swiper', {
            modules: [Navigation],
            slidesPerView: 4,
            loop: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            slideToClickedSlide: true,
            spaceBetween: 60,
            speed: 500,
            navigation: {
                nextEl: '.swiper-design__next',
                prevEl: '.swiper-design__prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 14,
                },
                661: {
                    slidesPerView: 3,
                    spaceBetween: 65,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                },
            },
        });
    }

    if (leftPicSwiperContainer) {
        new Swiper('.left-pic__swiper', {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 50,
            loop: true,
            navigation: {
                nextEl: '.left-pic__next',
                prevEl: '.left-pic__prev',
            },
        });
    }

    if (giftCardsSwiperContainer) {
        new Swiper('.gift-cards-swiper', {
            modules: [Navigation],
            slidesPerView: 3,
            spaceBetween: 0,
            navigation: {
                nextEl: '.gift-cards-swiper__next',
                prevEl: '.gift-cards-swiper__prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                921: {
                    slidesPerView: 3,
                }
            },
        });
    }

}

initSwipers();

// smooth scroll

function initSmoothScroll() {
    const anchors = document.querySelectorAll('a[href*="#"]');

    if (!anchors.length) return;

    anchors.forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            if (anchor.classList.contains('tabs-link-js')) return;

            const sectionId = anchor.getAttribute('href').substr(1);
            const section = document.getElementById(sectionId);

            if (section) {
                e.preventDefault();

                let yOffset = 0;

                if (window.innerWidth <= 660) {
                    yOffset = 49;
                }

                const y = section.getBoundingClientRect().top + window.pageYOffset - yOffset;

                window.scrollTo({top: y, behavior: 'smooth'});
            }
        });
    });
}

initSmoothScroll();

function initAnchorsTabs() {
    const tabsLinks = [...document.querySelectorAll('.tabs-link-js')];
    const tabsPanes = [...document.querySelectorAll('.tabs-pane-js')];

    if (!tabsLinks.length || !tabsPanes.length) return;

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    document.addEventListener('scroll', function () {
        if (window.innerWidth <= 1024) return;
        tabsPanes.forEach(pane => {
            if (isInViewport(pane)) {
                let activePaneId = pane.getAttribute('id');
                let activeLink = tabsLinks.find(link => link.getAttribute('href').substring(1) === activePaneId)
                tabsLinks.forEach(link => {
                    link.classList.remove('active');
                });
                activeLink.classList.add('active');
            }
        });
    });

    tabsLinks.forEach(link => {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            let activePaneId = link.getAttribute('href').substring(1);
            let activePane = tabsPanes.find(pane => pane.getAttribute('id') === activePaneId);

            tabsLinks.forEach(_link => {
                _link.classList.remove('show');
            });
            tabsPanes.forEach(pane => {
                pane.classList.remove('show');
            });
            link.classList.add('show');
            activePane.classList.add('show');

            if (window.innerWidth <= 1024) return;

            activePane.scrollIntoView({block: "center", behavior: "smooth"})

        });
    })
}

initAnchorsTabs();

function introCardsHandler() {
    const introCards = [...document.querySelectorAll('.intro-card')];

    if (!introCards.length) return;

    function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }

    const cardTitleHeights = [];

    introCards.forEach(card => {
        const cardTitle = card.querySelector('.intro-card__title');
        cardTitle.style.minHeight = 'auto';
        cardTitleHeights.push(cardTitle.scrollHeight);
    });

    introCards.forEach(card => {
        const cardTitle = card.querySelector('.intro-card__title');
        cardTitle.style.minHeight = `${getMaxOfArray(cardTitleHeights)}px`;
    });

}

introCardsHandler();

window.addEventListener('resize', introCardsHandler);

// info swipers

function infoSwipersInit() {
    const sections = document.querySelectorAll('.info-swiper');

    if (!sections.length) return;

    sections.forEach((section, index) => {

        const swiper = section.querySelector('.swiper-info');

        if (!swiper) return;

        const btnNext = section.querySelector('.info-swiper__next');
        const btnPrev = section.querySelector('.info-swiper__prev');
        const swiperNav = section.querySelector('.js-swiper-nav');
        const navItems = [...swiperNav.querySelectorAll('.page-list__item')];

        swiper.classList.add('swiper-info' + '-' + index);

        let newSwiper = new Swiper('.swiper-info-' + index, {
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            spaceBetween: 10,
            navigation: {
                nextEl: btnNext,
                prevEl: btnPrev,
            }
        });

        newSwiper.on('slideChange', function () {
            let activeIndex = newSwiper.realIndex;

            navItems.forEach(item => {
                item.classList.remove('active');
            });

            let activeNavItem = navItems.find(item => +item.dataset.slideIndex + 1 === activeIndex);

            if (activeNavItem) activeNavItem.classList.add('active');

        });

        navItems.forEach(item => {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                newSwiper.slideToLoop(+item.dataset.slideIndex + 1)
            });
        });
    });
}

infoSwipersInit();

// tabs

function initTabs() {
    const tabsEls = [...document.querySelectorAll('.tabs')];

    if (!tabsEls.length) return;

    function setFade(el) {
        el.classList.add('fade');
    }

    function removeFade(el) {
        el.classList.remove('fade');
    }

    function setActive(el) {
        el.classList.add('active');
    }

    function removeActive(el) {
        el.classList.remove('active');
    }

    function showPane(el) {
        setFade(el);
        setTimeout(setActive, 0, el);
    }

    function hidePane(el) {
        removeFade(el);
        removeActive(el);
    }

    tabsEls.forEach(el => {
        const tabsLinks = [...el.querySelectorAll('.tabs__link')];
        const tabsPanes = [...el.querySelectorAll('.tabs__pane')]

        if (!tabsLinks.length) return;

        tabsLinks.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                let activePane = tabsPanes.find(pane => link.dataset.tabsLink === pane.dataset.tabsPane);

                tabsPanes.forEach(pane => {
                    hidePane(pane);
                });

                tabsLinks.forEach(_link => {
                    removeActive(_link)
                });

                showPane(activePane);
                setActive(link);
            });
        });
    });
}

initTabs();

// tabs swipers

function initTabsSwipers() {
    const navSwiperContainer = document.querySelector('.tabs-swipers__nav');

    if (navSwiperContainer) {
        new Swiper('.tabs-swipers__nav', {
            slidesPerView: 'auto',
            breakpoints: {
                320: {
                    spaceBetween: 7,
                },
                661: {
                    spaceBetween: 0,
                },
            },
        });
    }

    const tabsSwipers = [...document.querySelectorAll('.tabs-swiper')];

    if (!tabsSwipers.length) return;

    tabsSwipers.forEach((swiper, index) => {
        const slides = swiper.querySelectorAll('.swiper-slide')
        const btnNext = swiper.querySelector('.tabs-slide__next');
        const btnPrev = swiper.querySelector('.tabs-slide__prev');
        const description = swiper.querySelector('.tabs-slide__description');

        swiper.classList.add('tabs-swiper' + '-' + index);

        if (slides.length > 1) {
            new Swiper('.tabs-swiper-' + index, {
                modules: [Navigation],
                slidesPerView: 1,
                loop: true,
                spaceBetween: 10,
                navigation: {
                    nextEl: btnNext,
                    prevEl: btnPrev,
                }
            });
        } else {
            btnNext.style.display = 'none';
            btnPrev.style.display = 'none';
            description.style.paddingLeft = '0';
        }

    });
}

initTabsSwipers();

function initCustomSelects() {
    const selects = [...document.querySelectorAll('.select')];

    if (!selects.length) return;

    selects.forEach(select => {

        if (select.classList.contains('select-init')) return;
        select.classList.add('select-init');

        const selectTop = select.querySelector('.select__top');
        const selectValue = select.querySelector('.select__top-value');
        const selectItems = [...select.querySelectorAll('.select__item')];
        const selectClear = select.querySelector('.select__item-clear');

        selectTop.addEventListener('click', function () {
            select.classList.toggle('active');
        });

        document.addEventListener('click', function (e) {
            if (e.path.indexOf(selectTop) < 0) {
                select.classList.remove('active');
            }
        });

        selectItems.forEach(item => {
            item.addEventListener('click', function () {
                select.classList.add('selected');
                selectValue.innerHTML = item.textContent.trim();
            });

            const itemRadio = item.querySelector('.select__item-input');

            if (itemRadio.getAttribute('checked') === null) return;

            select.classList.add('selected');
            selectValue.innerHTML = item.textContent.trim();
        });

        if (!selectClear) return;

        selectClear.addEventListener('click', function () {
            select.classList.remove('selected');
            selectValue.innerHTML = '';

            selectItems.forEach(item => {
                const itemRadio = item.querySelector('.select__item-input');
                itemRadio.checked = false;
            });
        });
    })
}

initCustomSelects();

function initCardTypesSwiper() {
    const cardTypesSection = document.querySelector('.card-types');

    if (!cardTypesSection) return;

    let swiper = null;
    let init = false;
    const tabsLinks = [...cardTypesSection.querySelectorAll('.tabs-link-js')];

    function swiperMode() {
        let tablet = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
        let desktop = window.matchMedia('(min-width: 1025px)');

        if (tablet.matches) {
            if (!init) {
                init = true;
                swiper = new Swiper('.card-types__swiper', {
                    slidesPerView: 1,
                    autoHeight: true,
                });
                swiper.on('slideChange', function () {
                    tabsLinks.forEach(link => {
                        link.classList.remove('show');
                    });
                    tabsLinks[swiper.activeIndex].classList.add('show');
                });
                tabsLinks.forEach(link => {
                    link.addEventListener('click', function (e) {
                        e.preventDefault();
                        swiper.slideTo(tabsLinks.indexOf(link));
                    });
                });
            }

        } else if (desktop.matches) {
            if (swiper) {
                swiper.destroy();
            }
            init = false;
        }
    }

    window.addEventListener('load', function () {
        swiperMode();
    });

    window.addEventListener('resize', function () {
        swiperMode();
    });

}

initCardTypesSwiper();

function navigation() {

    function markCurrent() {
        const nav = document.getElementsByClassName('header-menu__nav')[0];
        const links = nav.querySelectorAll('a[data-active][data-has-anchor]');
        links.forEach((link) => {
            const href = link.getAttribute('href');
            if (href === window.location.href) {
                link.classList.add('active')
            } else {
                link.classList.remove('active');
            }
        })
    }

    markCurrent();

    addEventListener('hashchange', event => {
        markCurrent();
    });
}

navigation();

function scrollDownBtn() {
    const intro = document.querySelector('.js-intro');
    const scrollDownBtn = document.querySelector('.js-scroll-down');

    if (!scrollDownBtn || !intro) return;

    let introHeight = intro.scrollHeight;

    scrollDownBtn.addEventListener('click', function(e) {
        e.preventDefault();
        window.scrollTo({
            top: introHeight,
            behavior: "smooth",
        });
    });

    window.addEventListener('resize', function() {
        introHeight = intro.scrollHeight;
    })
}
scrollDownBtn();

// noinspection JSUnresolvedFunction
AOS.init();
