<template>
    <div class="root">
        <post v-for="post in items"
              :key="post.id"
              :image="post.image"
              :image_alt="post.imageAlt"
              :title="post.title"
              :announce="post.announce"
              :url="post.url"
              :published_at="post.publishedAt"
        ></post>
        <button
            v-show="meta.currentPage < meta.lastPage"
            v-on:click.prevent="loadPosts"
            class="press-list__btn btn" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            {{ localized.show_more_button }}
        </button>
    </div>
</template>

<style scoped>
.root {
    display: flex;
    flex-direction: column
}
</style>

<script>
import Post from './post';
import axios from "axios";
import AOS from "aos";

export default {
    props: [
        'api_endpoint',
        'current_page',
        'last_page',
        'lang'
    ],
    components: {
        Post
    },
    data: function () {
        const lang = JSON.parse(this.lang);

        return {
            items: [],
            meta: {
                currentPage: parseInt(this.current_page),
                lastPage: parseInt(this.last_page),
            },
            localized: {
                show_more_button: lang?.show_more_button ?? 'views/pages/posts/index.posts-collection.show_more_button'
            }
        }
    },
    methods: {
        loadPosts() {
            axios.get(this.api_endpoint + '?page=' + this.getNextPage()).then(
                (response) => {
                    const data = response.data;
                    this.meta.currentPage = data.meta.currentPage;
                    this.meta.lastPage = data.meta.lastPage;
                    data.items.forEach((item) => {
                        this.items.push(item)
                    })
                }
            )
        },
        getNextPage() {
            return this.meta.currentPage + 1;
        }
    },
    updated() {
        // noinspection JSUnresolvedFunction
        AOS.init();
    }
}
</script>
