<template>
    <div class="press-list__item press-card">
        <div class="press-card__pic" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <a :href="url" class="press-card__link">
                <img :src="image" :alt="image_alt">
            </a>
        </div>
        <div class="press-card__content" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <div class="press-card__date">{{ published_at }}</div>
            <div class="press-card__title">
                <a :href="url" class="press-card__link">
                    {{ title }}
                </a>
            </div>
            <div class="press-card__text">
                <div v-html="announce"></div>
                <a :href="url" class="press-card__gradient-link"></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'image',
        'image_alt',
        'title',
        'announce',
        'url',
        'published_at'
    ]
}
</script>
