import _ from 'lodash';

export default {
    methods: {
        $renderServerSideValidationErrors: function (err) {
            if (err.response.status !== 422) {
                return;
            }

            const errors = err.response.data.errors;
            _.forEach(errors, (value, key) => {
                // noinspection JSUnresolvedFunction
                this.$refs[key].applyResult({
                    errors: [value[0]],
                    valid: false,
                    failedRules: {}
                });
            })
        }
    }
}
